import React, { useState } from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";

import axios from "axios";

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const ContactPage = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState();

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    const token = await executeRecaptcha("contactForm");
    const form = e.target;
    const data = new FormData(form);
    data.append("g-recaptcha-response", token);
    data.append("source", "contact-form");
    axios({
      method: "post",
      url: process.env.GATSBY_CONTACT_URL,
      headers: { "Content-Type": "multipart/form-data" },
      data,
    })
      .then((r) => {
        setProcessing(false);
        if (typeof window !== "undefined") {
          window.location = "/success/";
        }
      })
      .catch((r) => {
        setError("An error occurred, please try again later");
        setProcessing(false);
      });
  };

  return (
    <Layout>
      <Seo
        title="Contact"
        description="Contact Enabli to discuss how our Performance Management software can improve your team's performance."
      />
      <section className="bg-blue-dk">
        <div className="container">
          <div className="row">
            <div className="hero-subpage">
              <div className="hero-subpage-text">
                <h1>Contact</h1>
                <p className="lead">
                  If you have any questions about Enabli please view our{" "}
                  <Link to="/faqs">FAQs</Link>. For other business or product
                  enquiries please complete the form below.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="last-section with-pad-top">
        <div className="container">
          <div className="row">
            <form
              method="post"
              name="contact"
              id="contact"
              onSubmit={handleOnSubmit}
            >
              <div className="form-row">
                <div className="form-item w-50">
                  <label className="label" htmlFor="usersname">
                    Name
                  </label>
                  <input
                    type="text"
                    className="input"
                    placeholder="Enter your name"
                    name="usersname"
                    id="usersname"
                    required
                  />
                </div>
                <div className="form-item w-50">
                  <label className="label" htmlFor="coname">
                    Company
                  </label>
                  <input
                    type="text"
                    className="input"
                    placeholder="Enter your name"
                    name="coname"
                    id="coname"
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-item w-50">
                  <label className="label" htmlFor="usersemail">
                    Email
                  </label>
                  <input
                    type="email"
                    className="input"
                    placeholder="Enter your email"
                    name="usersemail"
                    id="usersemail"
                    required
                  />
                </div>
                <div className="form-item w-50">
                  <label className="label" htmlFor="usersphone">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="input"
                    placeholder=""
                    name="usersphone"
                    id="usersphone"
                  />
                </div>
              </div>
              <div className="form-item">
                <label className="label" htmlFor="usershelp">
                  How can we help?
                </label>
                <textarea
                  className="input"
                  rows="5"
                  name="usershelp"
                  id="usershelp"
                  required
                ></textarea>
              </div>
              <div className="form-item">
                <button className="btn btn-lg btn-blue">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  );
};

const ContactPageWithRecaptcha = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcnUFciAAAAAPV3DD2sLA8YovKBy2EW8JX0Ksrx">
      <ContactPage />
    </GoogleReCaptchaProvider>
  );
};

export default ContactPageWithRecaptcha;
